import React, { Component } from 'react';
import { connect } from 'react-redux';
import NoContentBox from '../../components/common/NoContentBox';
import { Accordion, AccordionItem, Search } from 'carbon-components-react';
import { getPersonalSummary } from '../../actions/CandidateInfo';
import { BsStars } from 'react-icons/bs';

class CandidatePersonalSummary extends Component {
  constructor(props) {
    super(props);
    this.state = {
      searchKey: '',
    };
  }

  componentDidMount() {
    this.props.onRef && this.props.onRef(this);
    this.renderOnMount();
  }

  componentWillUnmount() {
    this.props.onRef && this.props.onRef(undefined);
  }

  renderOnMount = () => {
    const { appId } = this.props;
    if (appId) this.props.getPersonalSummary(appId);

    this.setState({ searchKey: '' });
  };

  handleOpenAccr = (name) => {
    this.setState({
      isOpenAccrVal: name,
    });
  };

  handleChange = (e) => {
    const { name, value } = e?.target || {};
    this.setState({
      [name]: value,
    });
  };

  render() {
    const { searchKey } = this.state;
    const { personalSummary } = this.props;
    const { insights_result } = personalSummary || {};
    const { gems } = insights_result || {};

    return (
      <div>
        <div className="d-flex">
          <div className="mt-2 h6 font-weight-bold">
            Candidate Overview - Key Traits
          </div>{' '}
          <span className="xpa-gen-ai ml-3" onClick={() => {}}>
            <BsStars
              size={20}
              style={{ color: 'rgb(255 191 0)', marginRight: '2px' }}
            />
            Generated using AI
          </span>
        </div>
        <hr />
        {gems && Array.isArray(gems) && gems.length > 0 ? (
          <Accordion className="" align="start">
            {gems.map((itm) => {
              const { gem_name, evaluation_result } = itm || {};
              return (
                <AccordionItem
                  className="gba-accor-content mt-2"
                  title={
                    <div className="bx--row align-items-center">
                      <div className="h6 font-weight-bold mb-1 bx--col-lg-12 bx--col-md-12">
                        {gem_name}
                      </div>
                    </div>
                  }>
                  <div style={{ lineHeight: 1.3 }}>{evaluation_result}</div>
                </AccordionItem>
              );
            })}
          </Accordion>
        ) : (
          <NoContentBox
            hideTile
            message="There are no AI Insights for your current selection"
          />
        )}

        <hr />
        <div>
          <div className="bx--row justify-content-center">
            <div className="bx--col-lg-10 mt-3">
              <Search
                id="search-1"
                labelText="Search"
                name="searchKey"
                onChange={this.handleChange}
                placeHolderText="Ask any questions about this candidate"
                size="xl"
                type="text"
                value={searchKey}
              />
            </div>
            {/* <div className="bx--col-lg-2 mt-3">
              <Button onClick={this.handleSearchSubmit} kind="primary">
                Submit
              </Button>
            </div> */}
          </div>
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state) => ({
  personalSummary: state.CandidateInfo.personalSummary,
});

const mapDispatchToProps = {
  getPersonalSummary,
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(CandidatePersonalSummary);
